export const useCookieStorage = () => {
  const setCookie = (key: string, value: string, days: number = 7) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
      value
    )}; expires=${expires}; path=/;`
  }

  const getCookie = (key: string): string | null => {
    const matches = document.cookie.match(
      '(^|;)\\s*' + encodeURIComponent(key) + '\\s*=\\s*([^;]+)'
    )
    return matches ? decodeURIComponent(matches[2]) : null
  }

  const removeCookie = (key: string) => {
    document.cookie = `${encodeURIComponent(
      key
    )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
  }

  return { setCookie, getCookie, removeCookie }
}