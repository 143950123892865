import React, { createContext, useState, useEffect } from 'react'
import { decodeString, logEvent, resetWindowLocationWithoutParams } from '../../Utils'
import { useQuery } from '../../Routing'
import { BRAZE_EVENTS, MasterLinkParams } from '../../Constants'
import { logBrazeEvent } from '../../Utils'

type LinkContextType = {
  masterLinkParams: MasterLinkParams | null
  branchInitialized: boolean
  sendMasterBrazeEvents: () => void
  getMasterLinkParamsFromLocal: () => MasterLinkParams | null
}

export const LinkContext = createContext<LinkContextType | null>(null)

export const LinkProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  let query = useQuery()
  const [masterLinkParams, setMasterLinkParams] = useState<MasterLinkParams | null>(null)
  const [branchInitialized, setBranchInitialized] = useState<boolean>(false)

  useEffect(() => {
    //Tracking referrals
    const referrerQuery = query && query.get('referrerId')
    //Check for other referral param
    const secondaryReferrerQuery = query && query.get('referrerUserId')
    //PLL login source (for new users) ie shield-team
    const marSource = query && query.get('pllm_source')
    //on site check in
    const oscParam = query && query.get('osc')
    let localParams = getMasterLinkParamsFromLocal()
    const incoming = {
      referrerUserId:
        decodeString(referrerQuery) || decodeString(secondaryReferrerQuery) || '',
      pllm_source: decodeString(marSource) || '',
      osc: decodeString(oscParam) || '',
    }
    let formatted = formatMasterLinkParams(localParams, incoming)
    storeMasterLinkParams(formatted)
    setMasterLinkParams(formatted)

    setupBranchDeepLinks()
  }, [])

  const setupBranchDeepLinks = () => {
    if (window?.isBranchInitialized) {
      setBranchInitialized(true)
    }
  }

  const getMasterLinkParamsFromLocal = () => {
    if (localStorage) {
      let params = localStorage.getItem('masterLinkParams')
      if (params && params !== undefined) {
        return JSON.parse(params)
      }
      return null
    }
    return null
  }

  const sendMasterBrazeEvents = async () => {
    try {
      let params = getMasterLinkParamsFromLocal()
      if (params?.osc) {
        await logBrazeEvent(BRAZE_EVENTS.on_site_check_in, {
          game_day: params?.osc,
        })
        logEvent(BRAZE_EVENTS.on_site_check_in, {
          game_day: params?.osc,
        })
        storeMasterLinkParams({
          ...params,
          osc: '',
        })

        resetWindowLocationWithoutParams(['osc'])
      }
    } catch (error) {
      console.error('Error sending master Braze events:', error)
    }
  }

  const formatMasterLinkParams = (
    localParams: MasterLinkParams,
    incomingParams: MasterLinkParams
  ) => {
    return {
      referrerUserId:
        localParams?.referrerUserId || incomingParams?.referrerUserId || '', //Don't allow overwrite
      pllm_source: localParams?.pllm_source || incomingParams?.pllm_source || '', //Don't allow overwrite
      osc: incomingParams?.osc || '', //Allow overwrite
    }
  }

  const storeMasterLinkParams = (formatted: MasterLinkParams) => {
    if (localStorage) {
      localStorage.setItem('masterLinkParams', JSON.stringify(formatted))
    }
  }

  return (
    <LinkContext.Provider
      value={{
        masterLinkParams,
        branchInitialized,
        sendMasterBrazeEvents,
        getMasterLinkParamsFromLocal,
      }}
    >
      {children}
    </LinkContext.Provider>
  )
}
