import { PlayLogs } from '../../Api'
import {
  PLAY_LOG_EVENT_TYPES,
  PLAY_LOG_TITLE_MAP,
  PlayLogFilterType,
} from '../../Constants/events'
export type PeriodLogs = Record<number, PlayLogs[]>

//eventType for play logs
export const logEnums = {
  shot: 'shot',
  faceoff: 'faceoff',
}

export const createPeriodLogsMap = (
  playLogs: PlayLogs[],
  filter: PlayLogFilterType
): PeriodLogs => {
  const filteredLogs = filterPlayLogs(playLogs, filter)
  return filteredLogs.reduce<PeriodLogs>(
    (acc: { [x: string]: PlayLogs[] }, log: PlayLogs) => {
      const period = log.period
      if (!acc[period]) acc[period] = []
      acc[period].push(log)
      return acc
    },
    {}
  )
}

export const filterPlayLogs = (logs: PlayLogs[], filter: string) => {
  if (logs.length < 1) return logs
  let filtered = {
    all: logs,
    scores: logs.filter((pl) => pl.eventType === PLAY_LOG_EVENT_TYPES.goal),
    shot: logs.filter(
      (pl) =>
        pl.eventType === PLAY_LOG_EVENT_TYPES.shot ||
        pl.eventType === PLAY_LOG_EVENT_TYPES.goal
    ),
    penalty: logs.filter((pl) => pl.eventType === PLAY_LOG_EVENT_TYPES.penalty),
    powerplayend: logs.filter(
      (pl) =>
        pl.eventType === PLAY_LOG_EVENT_TYPES.powerplayend ||
        pl.eventType === PLAY_LOG_EVENT_TYPES.penalty ||
        (pl?.shotType && pl.shotType.includes('MU'))
    ),
  }
  if (filter in filtered) {
    return filtered[filter]
  }
  return logs.filter((pl) => pl.eventType === filter)
}

export const filterPlayLogsByPeriod = (logs: PlayLogs[]) => {
  const filteredLogs = logs.filter((pl) => pl.eventType === PLAY_LOG_EVENT_TYPES.goal)
  return {
    1: filteredLogs.filter((pl) => pl.period === 1),
    2: filteredLogs.filter((pl) => pl.period === 2),
    3: filteredLogs.filter((pl) => pl.period === 3),
    4: filteredLogs.filter((pl) => pl.period === 4),
    5: filteredLogs.filter((pl) => pl.period > 4),
  }
}

export const convertToPlayLogTitle = (log: PlayLogs) => {
  if (log?.eventType in PLAY_LOG_TITLE_MAP) {
    const converter = PLAY_LOG_TITLE_MAP[log.eventType]
    if (typeof converter === 'function') {
      return converter(log)
    }
    return converter || ''
  }
  return log.eventType
}

export const formatLogPeriod = (period: number): string => {
  if (period < 5) {
    return `Q${period}`
  }
  return `OT`
}

export const formatLogSeconds = (seconds: number | null): string => {
  if (!seconds) return '00'
  return seconds.toString().padStart(2, '0')
}
