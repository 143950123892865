import { useEffect } from 'react'
export const useImportScript = (resourceUrl: string, attributes: Record<string, string | boolean>) => {
  useEffect(() => {
    if (!resourceUrl) return
    const s = document.createElement('script')
    s.src = resourceUrl
    s.async = true
    s.defer = true
    Object.entries(attributes).map(([key, value]) => {
      s.setAttribute(key, value as string)
    })
    document.body.appendChild(s)
    return () => {
      document.body.removeChild(s)
    }
  }, [resourceUrl])
}
