import React, { createContext, useState, useEffect, useRef } from 'react'
import {
  EventGQL,
  fetchEventByIdGQL,
  fetchPlayLogsByEvent,
  PlayLogs,
} from '../../Api'
import { isLiveGame, updateInProgressGame } from '../../Utils'

type BroadcastGameContextType = {
  gameEvent: EventGQL | null
  playLogs: PlayLogs[]
  fetchFails: { [key: string]: number }
  isEventLoading: boolean
  isLoadingPlayLogs: boolean
  isRequestPending: boolean
  isLogRequestPending: boolean
  fetchPlayLogs: (event: EventGQL) => Promise<void>
}

export const BroadcastGameContext = createContext<BroadcastGameContextType | null>(null)

export const BroadcastGameProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [gameEvent, setGameEvent] = useState<EventGQL | null>(null)
  const [playLogs, setPlayLogs] = useState<PlayLogs[]>([])
  const [isEventLoading, setIsEventLoading] = useState<boolean>(true)
  const [isLoadingPlayLogs, setIsLoadingPlayLogs] = useState<boolean>(true)
  const [fetchFails, setFetchFails] = useState<{ [key: string]: number }>({
    event: 0,
    playLogs: 0,
  })
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false)
  const [isLogRequestPending, setIsLogRequestPending] = useState<boolean>(false)
  const gameCheckIntervalRef = useRef<any | null>(null)
  const liveGameIntervalRef = useRef<any | null>(null)

  useEffect(() => {
    let slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    const id = slug.split('?')[0]
    fetchInitialEvent(id)
    return () => {
      if (gameCheckIntervalRef.current !== null) {
        clearInterval(gameCheckIntervalRef.current)
        gameCheckIntervalRef.current = null
        console.log('Cleared game check interval on unmount')
      }
      if (liveGameIntervalRef.current !== null) {
        clearInterval(liveGameIntervalRef.current)
        liveGameIntervalRef.current = null
        console.log('Cleared interval on unmount')
      }
    }
  }, [])

  const fetchInitialEvent = async (id: string) => {
    try {
      const ev = await fetchEventByIdGQL(id)
      setGameEvent(ev)
      setIsEventLoading(false)
      if (!ev) return
      if (ev.eventStatus > 0) {
        await fetchPlayLogs(ev)
      }
      runGameCheckInterval(ev)
      return
    } catch (err) {
      console.error(err)
      setIsEventLoading(false)
      return
    }
  }

  const runGameCheckInterval = (ev: EventGQL) => {
    if (gameCheckIntervalRef.current === null) {
      //Starting interval to go every 3 seconds
      gameCheckIntervalRef.current = setInterval(() => {
        let isLive = isLiveGame(ev)
        if (isLive) {
          startLiveGameInterval(ev)
        }
        checkGameViewerErrors()
      }, 3000)
    } else {
      console.log('Game check interval already running')
    }
  }

  const startLiveGameInterval = async (liveEv: EventGQL) => {
    if (liveGameIntervalRef.current !== null) return
    liveGameIntervalRef.current = setInterval(async () => {
      if (isRequestPending) {
        console.log('Request is pending:', isRequestPending)
        return
      }
      console.log('Fetching updated live game data')
      try {
        setIsRequestPending(true)
        const updatedEv = await updateInProgressGame(liveEv.slugname)
        setIsRequestPending(false)

        if (!updatedEv) {
          setFetchFails((prev) => ({ ...prev, event: prev.event + 1 }))
          return
        }

        setFetchFails((prev) => ({ ...prev, event: 0 }))
        setGameEvent(updatedEv)
        fetchPlayLogs(updatedEv)

        // Stop polling if game is over
        if (!isLiveGame(updatedEv)) {
          if (liveGameIntervalRef.current !== null) {
            clearInterval(liveGameIntervalRef.current)
            liveGameIntervalRef.current = null
          }
        }
        return
      } catch (error) {
        console.error('Error fetching live event:', error)
        setFetchFails((prev) => ({ ...prev, event: prev.event + 1 }))
        setIsRequestPending(false)
        return
      }
    }, 3000)
  }

  const fetchPlayLogs = async (event: EventGQL) => {
    if (isLogRequestPending) return
    setIsLogRequestPending(true)
    if (gameEvent?.eventStatus === 1 && gameEvent?.id === event.id) {
      await fetchNewPlayLogs(event)
      setIsLogRequestPending(false)
      return
    }

    if (
      playLogs.length > 0 &&
      gameEvent?.eventStatus !== 1 &&
      gameEvent?.id === event.id
    ) {
      setIsLoadingPlayLogs(false)
      setIsLogRequestPending(false)
      setFetchFails((prev) => ({ ...prev, playLogs: 0 }))
      return Promise.resolve()
    } else {
      //Need to get play logs from DB
      await fetchNewPlayLogs(event)
      setIsLogRequestPending(false)
      return
    }
  }

  const fetchNewPlayLogs = async (event: EventGQL) => {
    try {
      const logs = await fetchPlayLogsByEvent(event.slugname)
      if (!logs || logs.length === 0) {
        setIsLoadingPlayLogs(false)
        return []
      }
      const filtered = logs.filter(
        (l) =>
          !l.markerId.includes('-G') &&
          l.eventType !== 'periodend' &&
          l.eventType !== 'start' &&
          l.eventType !== 'periodstart'
      )
      setPlayLogs(filtered)
      setIsLoadingPlayLogs(false)
      setFetchFails((prev) => ({ ...prev, playLogs: 0 }))
      return
    } catch (err) {
      setIsLoadingPlayLogs(false)
      setFetchFails((prev) => ({ ...prev, playLogs: prev.playLogs + 1 }))
      console.log(err)
      return
    }
  }

  const checkGameViewerErrors = () => {
    if (fetchFails.event >= 5 || fetchFails.playLogs >= 5) {
      console.error('Too many fetch failures, resetting page...')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }


  return (
    <BroadcastGameContext.Provider
      value={{
        gameEvent,
        playLogs,
        isEventLoading,
        isLoadingPlayLogs,
        isRequestPending,
        isLogRequestPending,
        fetchFails,
        fetchPlayLogs,
      }}
    >
      {children}
    </BroadcastGameContext.Provider>
  )
}
