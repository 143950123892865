import { useImportScript } from './useImportScript'
// Level Access Widget
export const useUserWay = () => {
  useImportScript(
    process.env.NODE_ENV === 'production' ? 'https://cdn.userway.org/widget.js' : '',
    process.env.NODE_ENV === 'production'
      ? {
          'data-position': '5',
          'data-size': 'small',
          'data-account': 'xxj9FVwrpT',
        }
      : {}
  )
}
