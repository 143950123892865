import React, { useContext, useEffect, useState } from 'react'
import { SignInModal } from '../../Components/Auth'
import {
  getFirAuth,
  getActionCodeSettings,
  getGoogleProvider,
  GCredential,
  getGoogleAuthCredential,
} from './firebaseConnect'
import { getCurrentUser, NO_USER, setUpdatedUserData } from './AuthData'
import {
  checkIfEmbededInNativeApp,
  determineMasterParamsToAdd,
  logEvent,
  resetWindowLocationWithoutParams,
  useCookieStorage,
  logBrazeEvent,
} from '../../Utils'
import { useQuery } from '../../Routing'
import { User } from '../Users/types'
import { BRAZE_EVENTS, MasterLinkParams } from '../../Constants'
import { getAuth, type ConfirmationResult } from 'firebase/auth'
import { createUser, getUser } from '../Users'
import { initBrazeCore } from '../../Config/Braze/braze.config'
import { LinkContext } from '../../Context/LinkContext'
import { GOOGLE_CLIENT_ID } from '../../Config/Firebase/firebase.config'

type AuthContextType = User & {
  authLoading: boolean
  login: (extraFields?: string[]) => void
  logout: () => void
  handleToggleLogin: () => void
  updateProfileInState: (userItem: any) => void
  sendPhoneVerification: (phone: string) => Promise<ConfirmationResult | null>
  getMissingUserFields: (fields: string[]) => string[]
}

export const AuthContext = React.createContext<AuthContextType | null>(null)

type AuthProviderProps = { children?: React.ReactNode }

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  let query = useQuery()
  const { getCookie, setCookie, removeCookie } = useCookieStorage()
  const { getMasterLinkParamsFromLocal, sendMasterBrazeEvents } =
    useContext(LinkContext)!
  const [user, setUser] = useState<User>(NO_USER)
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [showLogin, setShowLogin] = useState<{
    show: boolean
    extraFields?: string[]
  }>({ show: false, extraFields: [] })

  useEffect(() => {
    const handleAuthChange = async () => {
      await onAuthChange()
    }
    //Check if pending redirect
    getAuthRedirectResult()

    handleAuthChange() //Turn on auth listener

    //Cleanup
    return () => {
      onAuthChange()
    }
  }, [])

  /**
   * When the auth state changes, either user is logged in or logged out
   */
  const onAuthChange = async () => {
    const auth = await getFirAuth()
    if (auth) {
      const { isSignInWithEmailLink, onAuthStateChanged } = await import('firebase/auth')
      //Check custom token query param
      let fbctQuery = query.get('fbct')
      if (fbctQuery) {
        handleCustomToken(fbctQuery)
      }

      if (isSignInWithEmailLink(auth, window.location.href)) {
        //Email link signup
        handleEmailLink()
      }
      initializeGSI() //init Google One Tap

      onAuthStateChanged(auth, async (userRes) => {
        setAuthLoading(true)

        if (userRes) {
          // User is logged in
          await handleUserChange()
          //Send osc braze event for current user
          sendMasterBrazeEvents()
        } else {
          // User is logged out
          handleGoogleOneTapPrompt(NO_USER)
          handleAnonymousSignIn()
          //Send osc braze event for anon user
          sendMasterBrazeEvents()
          setShowLogin({ show: false, extraFields: [] })
          setAuthLoading(false)
        }
      })
    } else {
      setUser(NO_USER)
      setAuthLoading(false)
      setShowLogin({ show: false, extraFields: [] })
      if (checkIfEmbededInNativeApp()) {
        logEvent(`firebase_not_initialized_onAuthChange_embeded`)
      } else {
        logEvent(`firebase_not_initialized_onAuthChange`)
      }
    }
  }

  const handleUserChange = async () => {
    const usr = await getCurrentUser() //Firebase Auth user
    if (usr.isLoggedIn) {
      //Create or update user in PLL database
      let firUsr = usr
      const isNewUser = checkFirstTimeUser()
      let pllUser = await fetchUserFromDB(firUsr, isNewUser)
      if (pllUser) {
        setUserDataInState(pllUser)
      }
    } else {
      setUser(usr as User)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    }
  }

  const checkFirstTimeUser = () => {
    let user = getAuth()?.currentUser
    if (!user?.metadata?.creationTime || !user?.metadata?.lastSignInTime) return false
    const lastSignInTime = new Date(user?.metadata?.lastSignInTime).getTime()
    const creationTime = new Date(user?.metadata?.creationTime).getTime()
    return lastSignInTime - creationTime < 5000
  }

  const fetchUserFromDB = async (firUsr: User, isNewUser: boolean) => {
    let dbUser = null
    let linkParams: MasterLinkParams | null = null
    //Append master Link params
    const mParams = getMasterLinkParamsFromLocal()
    if (mParams && Object.keys(mParams).length > 0) {
      linkParams = determineMasterParamsToAdd(mParams, isNewUser)
    }
    if (isNewUser) {
      dbUser = await createUser(firUsr, linkParams)
    } else {
      dbUser = await getUser(linkParams)
      if (!dbUser) {
        logEvent('firebase_user_retry_createUser', {
          product: 'stats',
          user: firUsr?.uid,
        })
        linkParams = determineMasterParamsToAdd(mParams, true)
        dbUser = await createUser(firUsr, linkParams)
        //Log after multi fail
        if (!dbUser) {
          logEvent('firebase_user_retry_createUser_failed', {
            product: 'stats',
            user: firUsr?.uid,
          })
        }
      }
    }
    return dbUser
  }

  const setUserDataInState = async (userRes: User) => {
    const userAuth = await setUpdatedUserData(userRes)
    setUser(userAuth)
    setAuthLoading(false)
    if (userAuth.uid) {
      const success = await initBrazeCore(userAuth.uid)
      if (success) {
        logBrazeEvent(BRAZE_EVENTS.log_in)
      }
    }
  }

  /**
   * Update profile in state
   */
  const updateProfileInState = (userItem: any) => {
    const currentAuth = { ...user }
    if (userItem.favTeam) {
      setUser({ ...currentAuth, ...userItem })
      return
    }
    setUser({ ...currentAuth, ...userItem })
    return
  }

  /**
   * Sign user in anonymously
   */
  const handleAnonymousSignIn = async () => {
    // Initialize Braze for anonymous users
    const success = await initBrazeCore()
    if (success) {
      logBrazeEvent(BRAZE_EVENTS.anonymous_session_start)
    }
    setAuthLoading(false)
  }

  /**
   * Loading the google one tap signup
   */
  const initializeGSI = () => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (!isEmbedded && window?.google && window.google?.accounts && GOOGLE_CLIENT_ID) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: (token: GCredential) => {
          handleToken(token)
        },
      })
    }
  }

  /**
   * Handle token response from google one click
   */
  const handleToken = async (token: any) => {
    const auth = await getFirAuth()
    let cred = await getGoogleAuthCredential(token.credential)
    if (auth) {
      const { signInWithCredential } = await import('firebase/auth')
      await signInWithCredential(auth, cred)
    }
  }

  const handleGoogleOneTapPrompt = (usr: User) => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (isEmbedded) {
      //Don't show one tap in native app
      window.google.accounts.id.cancel()
      return
    }
    if (window?.google?.accounts && window.google.accounts?.id && GOOGLE_CLIENT_ID) {
      if (!usr.isLoggedIn || usr.isAnonymous) {
        //Show one click
        window.google.accounts.id.prompt()
        return
      }
      if (usr.isLoggedIn && !usr.isAnonymous) {
        //Hide one click after log in
        window.google.accounts.id.cancel()
      }
    }
    return
  }

  const handleEmailLink = async () => {
    const auth = await getFirAuth()
    if (auth) {
      let email = ''
      if (window?.localStorage) {
        email = window.localStorage.getItem('pll_temp_em') || ''
      }
      if (!email) {
        email = tryEmFromCookie()
      }
      if (!email) {
        const em = window.prompt('Please confirm your email')
        if (em) {
          email = em.toLowerCase()
        }
      }
      try {
        const { signInWithEmailLink } = await import('firebase/auth')
        const result = await signInWithEmailLink(auth, email!, window.location.href)
        if (result?.user?.uid) {
          window.localStorage.removeItem('pll_temp_em')
          removeCookie('pll_temp_em')
        }
      } catch (err) {
        logEvent('loginError_handleEmailLink', {
          provider: 'email',
          error: err,
          product: 'stats',
        })
        console.log(err)
      }
    }
  }

  /**
   * Check temp email from cookie
   */
  const tryEmFromCookie = () => {
    let email = ''
    const cookieEm = getCookie('pll_temp_em')

    if (cookieEm) {
      email = cookieEm.toLowerCase()
    }
    return email
  }

  const signInWithSocial = async (provider: string) => {
    const auth = await getFirAuth()
    if (auth) {
      if (provider !== 'google') return
      try {
        await signInWithGooglePopup(auth)
      } catch (err: any) {
        console.log(err)
        logEvent('loginError_signInWithSocial', {
          provider: provider,
          error: err,
          product: 'stats',
        })
        setShowLogin({ show: !setShowLogin, extraFields: [] })
        throw new Error(err)
      }
    }
  }

  /**
   * Use sign up with popup for just Gmail
   */
  const signInWithGooglePopup = async (auth: any) => {
    const p = await getGoogleProvider()
    if (!p) return
    try {
      const { signInWithPopup } = await import('firebase/auth')
      let result = await signInWithPopup(auth, p)
      if (result?.user?.uid) {
        setShowLogin({ show: !setShowLogin, extraFields: [] })
      }
    } catch (err: any) {
      logEvent('loginError_signInWithGooglePopup', {
        provider: 'google',
        error: err,
        product: 'stats',
      })
    }
  }

  const getAuthRedirectResult = async () => {
    const auth = await getFirAuth()
    if (auth) {
      try {
        const { getRedirectResult } = await import('firebase/auth')
        await getRedirectResult(auth)
      } catch (err: any) {
        if (!err.credential) return
        const { signInWithCredential } = await import('firebase/auth')
        await signInWithCredential(auth, err.credential)
      }
    }
  }

  /**
   * Handle if custom token is passed in
   */
  const handleCustomToken = async (tk: string) => {
    const auth = await getFirAuth()
    if (auth) {
      try {
        const { signInWithCustomToken } = await import('firebase/auth')
        const result = await signInWithCustomToken(auth, tk)
        if (result?.user?.uid) {
          resetWindowLocationWithoutParams(['fbct'])
        }
      } catch (err) {
        console.log(err)
        resetWindowLocationWithoutParams(['fbct'])
      }
    }
  }

  const signInWithEmLink = async (redirectTo: string, email: string) => {
    const auth = await getFirAuth()
    if (auth) {
      let actionCodeSettings = getActionCodeSettings(
        redirectTo || window.location.hostname
      )
      try {
        const { sendSignInLinkToEmail } = await import('firebase/auth')
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem('pll_temp_em', email)
        setCookie('pll_temp_em', email)
        return true
      } catch (err: any) {
        return false
      }
    }
    return false
  }

  // PHONE LOGIN //
  const sendPhoneVerification = async (phoneNumber: string) => {
    const auth = await getFirAuth()
    const { signInWithPhoneNumber } = await import('firebase/auth')
    let verifier = await getAppVerifier('phone-signin-btn', auth)
    if (verifier && auth) {
      try {
        let confirmationResult: ConfirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          verifier
        )
        return confirmationResult
      } catch (err) {
        console.log(err)
        logEvent('loginError_sendPhoneVerification', {
          provider: 'phone',
          error: err,
          product: 'stats',
        })
        return null
      }
    } else {
      return null
    }
  }

  const getAppVerifier = async (
    verifierId: string | HTMLElement,
    auth: any | undefined
  ) => {
    if (auth) {
      const { RecaptchaVerifier } = await import('firebase/auth')
      return new RecaptchaVerifier(auth, verifierId, {
        size: 'invisible',
      })
    } else {
      return null
    }
  }

  const login = (extraFields?: string[]) => {
    if (extraFields) {
      setShowLogin({ show: true, extraFields })
      return
    }
    setShowLogin({ show: true, extraFields: [] })
  }

  const handleToggleLogin = () => {
    setShowLogin({ show: !setShowLogin, extraFields: [] })
  }

  const logout = async () => {
    const fba = await getFirAuth()
    if (fba) {
      setUser(NO_USER)
      fba.signOut()
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
      logBrazeEvent(BRAZE_EVENTS.log_out)
    }
  }

  /**
   * Get fields user has not filled in on profile
   */
  const getMissingUserFields = (fields: string[]): string[] => {
    if (fields.length === 0) return []
    return fields.filter((field) => field in user && !user[field as keyof User])
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        authLoading,
        handleToggleLogin,
        login,
        logout,
        sendPhoneVerification,
        getMissingUserFields,
        updateProfileInState,
      }}
    >
      {showLogin.show && (
        <SignInModal
          onClose={() => {
            logEvent('login_cancelled')
            handleToggleLogin()
          }}
          signInWithSocial={signInWithSocial}
          signInWithEmLink={signInWithEmLink}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}
