import { getBrazeSDK } from '../../Config/Braze/braze.config'
import { BRAZE_EVENTS } from '../../Constants/analytics'

const MAX_RETRIES = 2
const RETRY_DELAY = 1000 // 1 second

type BrazeOperation<T> = (braze: any) => T

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Executes a Braze operation with retry logic
 * @param operation The operation to execute
 * @param errorContext Context for error logging
 * @returns The result of the operation or undefined if all retries fail
 */
const executeWithRetry = async <T>(
  operation: BrazeOperation<T>,
  errorContext: string
): Promise<T | undefined> => {
  let retries = 0
  while (retries < MAX_RETRIES) {
    try {
      const braze = await getBrazeSDK()
      if (!braze) {
        throw new Error('Braze SDK not available')
      }

      return operation(braze)
    } catch (e) {
      retries++
      if (retries === MAX_RETRIES) {
        console.error('[Braze] Failed:', errorContext, e)
        return undefined
      }
      await wait(RETRY_DELAY * retries)
    }
  }
  return undefined
}

export const logBrazeEvent = async (name: string, params?: any | undefined) => {
  await executeWithRetry(
    (braze) => braze.logCustomEvent(name, params),
    `log event: ${name}`
  )
}

export const setBrazeAttribute = async (attributeName: string, property: any) => {
  await executeWithRetry((braze) => {
    const brazeUser = braze.getUser()
    if (brazeUser) {
      brazeUser.setCustomUserAttribute(attributeName, property)
    }
  }, `set attribute: ${attributeName}`)
}

export const logBrazePageView = async (
  pageName: string,
  additionalParams?: Record<string, any>
) => {
  await executeWithRetry((braze) => {
    const eventName = BRAZE_EVENTS.page_view + pageName
    const eventParams = {
      page_name: pageName,
      ...additionalParams,
    }
    braze.logCustomEvent(eventName, eventParams)
  }, `log page view: ${pageName}`)
}

export const setUserAlias = async (aliasName: string, aliasLabel: string) => {
  await executeWithRetry((braze) => {
    const brazeUser = braze.getUser()
    if (brazeUser) {
      brazeUser.addAlias(aliasName, aliasLabel)
    }
  }, `set user alias: ${aliasName}`)
}
