import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { logBrazePageView } from '../../Utils'
import { useContext } from 'react'
import { AuthContext } from '../../Api/firebase/authContext'
import { useBraze } from '../../Context/BrazeContext/BrazeProvider'

/**
 * Hook to track page views for Braze
 * It waits for both Braze to be initialized and auth to finish loading before tracking
 * @param brazeInitialized Whether Braze is initialized
 */
export const useBrazePageTracking = (brazeInitialized?: boolean) => {
  const location = useLocation()
  const auth = useContext(AuthContext)!
  const { authLoading, isAnonymous, isLoggedIn } = auth
  const { brazeError } = useBraze()
  const lastTrackedPath = useRef<string>('')

  useEffect(() => {
    if (!brazeInitialized || authLoading) return
    if (!isLoggedIn && !isAnonymous) return
    if (brazeError) {
      console.error('[Braze] Page tracking disabled:', brazeError)
      return
    }

    const pathname = location.pathname
    if (pathname === lastTrackedPath.current) return

    const pageName = pathname.split('/').filter(Boolean).join('_') || 'home'
    logBrazePageView(pageName, {
      url: pathname,
      query_params: location.search,
      timestamp: new Date().toISOString(),
      is_anonymous: isAnonymous,
    })

    lastTrackedPath.current = pathname
  }, [location, brazeInitialized, authLoading, isAnonymous, isLoggedIn, brazeError])
}

/**
 * Component that uses the useBrazePageTracking hook
 */
export const BrazePageTrackingComponent: React.FC<{ brazeInitialized: boolean }> = ({
  brazeInitialized,
}) => {
  useBrazePageTracking(brazeInitialized)
  return null
}
