import React from 'react'
import { SubMenu } from './SubMenu'
import { PLL_LINKS, TEAM_MENU, MORE_MENU } from '../../../Config/League/league.config'
import { MobileNavCon, MobileNavList } from '../SiteNavbar/styles'
import { createShopUTM } from '../../../Routing'
import { Lk } from '../../../Utils/routing'

type MenuProps = {
  open: boolean
}

export const NavItems: React.FC<MenuProps> = ({ open }) => {

  return (
    <MobileNavCon open={open}>
      <MobileNavList open={open}>
        <SubMenu menuText="Teams ▾" options={TEAM_MENU}></SubMenu>
        <li>
          <Lk link={PLL_LINKS.schedule} alt={`schedule-${PLL_LINKS.schedule}`}>Schedule</Lk>
        </li>
        <li>
          <Lk link={PLL_LINKS.standings} alt={`standings-${PLL_LINKS.standings}`}>Standings</Lk>
        </li>
        <li>
          <Lk link={PLL_LINKS.fantasy} alt={`fantasy-${PLL_LINKS.fantasy}`}>Fantasy</Lk>
        </li>
        <li>
          <Lk link={PLL_LINKS.academy} alt={`academy-${PLL_LINKS.academy}`}>PLL Play</Lk>
        </li>
        <li>
          <Lk link={PLL_LINKS.whereToWatch} alt={`where-to-watch-${PLL_LINKS.whereToWatch}`}>Where To Watch</Lk>
        </li>
        <li>
          <Lk link={createShopUTM({
            slug: '',
            medium: 'PLL_STATS',
            campaign: 'STATS_NAVBAR'
          })} alt={`shop-${createShopUTM({
            slug: '',
            medium: 'PLL_STATS',
            campaign: 'STATS_NAVBAR'
          })}`}>Shop</Lk>
        </li>
        <SubMenu menuText="More ▾" options={MORE_MENU}></SubMenu>
      </MobileNavList>
    </MobileNavCon>
  )
}
