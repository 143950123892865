import { PlayLogs } from '../Api'

export type PlayLogEventType =
  | 'shot'
  | 'goal'
  | 'faceoff'
  | 'groundball'
  | 'penalty'
  | 'powerplayend'
  | 'turnover'
  | 'shotclockexpired'
  | 'ballcleared'
  | 'timeoutcalled'

export type PlayLogDescriptorType = 'SHOT_GOOD' | 'MU' | 'MU_TWO_PT' | '2_PT' | 'BONUS'
export type PlayLogFilterType = 'scores' | 'all' | 'faceoff' | 'groundball' | 'turnover' | 'shot' | 'shotclockexpired' | 'penalty'

export const playLogSelectTypes = [
  { label: 'All Plays', value: 'all' },
  { label: 'Scoring Plays', value: 'scores' },
  { label: 'Faceoffs', value: 'faceoff' },
  { label: 'Shooting', value: 'shot' },
  { label: 'Power Play', value: 'powerplayend' },
  { label: 'Shot Clock Exp', value: 'shotclockexpired' },
  { label: 'Turnovers', value: 'turnover' },
  { label: 'Penalties', value: 'penalty' }
]

export const PLAY_LOG_EVENT_TYPES: { [key: string]: PlayLogEventType } = {
  shot: 'shot',
  goal: 'goal',
  faceoff: 'faceoff',
  groundball: 'groundball',
  penalty: 'penalty',
  powerplayend: 'powerplayend',
  turnover: 'turnover',
  shotclockexpired: 'shotclockexpired',
  ballcleared: 'ballcleared',
  timeoutcalled: 'timeoutcalled',
}

export const PLAY_LOG_DESCRIPTOR_TYPES: { [key: string]: PlayLogDescriptorType } = {
  SHOT_GOOD: 'SHOT_GOOD',
  TWO_PT: '2_PT',
  MU_SHOT: 'MU',
  MU_2_PT_SHOT: 'MU_TWO_PT',
  BONUS: 'BONUS',
}

export const PLAY_LOG_TITLE_MAP: {
  [key: string]: string | ((log: PlayLogs) => string)
} = {
  shot: 'Shot',
  goal: (log: PlayLogs) =>
    (log.shotType && log.shotType.includes(PLAY_LOG_DESCRIPTOR_TYPES.MU_SHOT)) ? 'PP Goal' : 'Goal',
  faceoff: 'Faceoff',
  turnover: 'Turnover',
  groundball: 'Groundball',
  ballcleared: 'Clear',
  shotclockexpired: 'Violation',
  timeoutcalled: 'Timeout',
  powerplayend: 'Power Play',
  penalty: 'Penalty',
  pregame: 'Game Start',
  gameEnd: 'Final',
}
