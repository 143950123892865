import React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'

type LKParams = {
  link?: string
  alt: string
  onClick?: (params: any) => void
  classNameObj?: React.HTMLAttributes<HTMLDivElement> | string
  target?: string
  rel?: string
  role?: string
}

const DefaultLinkStyle = styled('div')(({ theme }) => ({
  '& .default': {
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}))

export const Lk: React.FC<React.PropsWithChildren<LKParams>> = ({
  link,
  classNameObj,
  onClick,
  children,
  alt,
  role,
}) => {
  const navigate = useNavigate()

  return (
    <DefaultLinkStyle
      className={classNameObj ? `${classNameObj}` : 'default'}
      onClick={() => onClick}
    >
      {link && link?.includes('http') && (
        <a href={link} role={role} aria-label={alt}>
          {children}
        </a>
      )}
      {link && !link?.includes('http') && (
        <div onClick={() => navigate(link)} role={role}>
          {children}
        </div>
      )}
    </DefaultLinkStyle>
  )
}
