import React, { createContext, useContext, useEffect, useState } from 'react'
import { getBrazeSDK, initBrazeCore } from '../../Config/Braze/braze.config'
// import { BrazeInAppMessagesComponent } from '../../Hooks/Braze/useBrazeInAppMessages'
import { BrazePageTrackingComponent } from '../../Hooks/Braze/useBrazePageTracking'

type BrazeContextType = {
  brazeInitialized: boolean
  brazeError: Error | null
}

export const BrazeContext = createContext<BrazeContextType | null>(null)

export const useBraze = () => {
  const context = useContext(BrazeContext)
  if (!context) {
    throw new Error('useBraze must be used within a BrazeProvider')
  }
  return context
}

type BrazeProviderProps = {
  children: React.ReactNode
}

export const BrazeProvider: React.FC<BrazeProviderProps> = ({ children }) => {
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)
  const [brazeError, setBrazeError] = useState<Error | null>(null)

  useEffect(() => {
    const startBraze = async () => {
      try {
        const braze = await getBrazeSDK()
        if (!braze) {
          throw new Error('Failed to load Braze SDK')
        }

        const success = await initBrazeCore()
        if (!success) {
          throw new Error('Failed to initialize Braze core')
        }

        setBrazeInitialized(true)
        setBrazeError(null)
      } catch (error) {
        console.error('[Braze] Initialization failed:', error)
        setBrazeError(
          error instanceof Error ? error : new Error('Unknown error initializing Braze')
        )
        setBrazeInitialized(false)
      }
    }

    startBraze()
  }, [])

  return (
    <BrazeContext.Provider value={{ brazeInitialized, brazeError }}>
      {children}
      {/* Initialize Braze features */}
      {/* <BrazeInAppMessagesComponent brazeInitialized={brazeInitialized} /> */}
      <BrazePageTrackingComponent brazeInitialized={brazeInitialized} />
    </BrazeContext.Provider>
  )
}
