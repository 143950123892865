import React, { useEffect, useRef, useState } from 'react'
import { ADSENSE_CLIENT_ID, AD_OPTIONS } from '../../Config/Ads/ads.config'
import { AdCon } from './styles'
import { BottomCon } from '../Layout/Overlays/styles'
import { useAdContext } from '../../Context/AdContext/AdContext'

type AdProps = {
  bannerType: string
  bottomFixed?: boolean
  inContent?: boolean
  ariaLabel?: string
}

export const AdUnit: React.FC<AdProps> = ({
  bannerType,
  bottomFixed = false,
  inContent = false,
  ariaLabel,
}) => {
  const { canShowAds, hasError } = useAdContext()
  const banner = AD_OPTIONS[bannerType]
  const isDevEnv = process.env.NODE_ENV === 'development'

  const adRef = useRef<HTMLModElement>(null)
  const adInitialized = useRef(false)
  const [adError, setAdError] = useState<string>()
  const [retryCount, setRetryCount] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      initializeAd()
    }, 100)

    return () => {
      clearTimeout(timer)
      adInitialized.current = false
      setRetryCount(0)
      setAdError(undefined)
    }
  }, [isDevEnv, canShowAds, hasError, banner, bannerType, retryCount])

  const initializeAd = () => {
    try {
      if (isDevEnv || hasError || !canShowAds || !banner || !window.adsbygoogle) return
      if (!adRef.current) {
        setAdError('Ad reference not found')
        return
      }
      adInitialized.current = false
      if (
        typeof window?.adsbygoogle === 'undefined' ||
        !Array.isArray(window.adsbygoogle)
      ) {
        window.adsbygoogle = window.adsbygoogle || []
      }

      window.adsbygoogle.push({})
      adInitialized.current = true
      setAdError(undefined)
    } catch (e) {
      const errorMessage = `Ad initialization error: ${e}`
      setAdError(errorMessage)
      console.error(errorMessage)

      if (retryCount < 3) {
        setTimeout(() => {
          setRetryCount((prev) => prev + 1)
        }, 2000)
      }
    }
  }

  if (isDevEnv || !canShowAds || !banner || hasError || adError) {
    if (adError) console.error(`Ad Unit Error (${bannerType}):`, adError)
    return null
  }

  const AdElement = (
    <ins
      className="adsbygoogle"
      aria-hidden
      style={{ display: 'block' }}
      data-ad-client={ADSENSE_CLIENT_ID}
      title={`${banner.type} - ${banner.slotId}`}
      aria-label={ariaLabel ?? `${banner.type} - ${banner.slotId}`}
      data-ad-slot={banner.slotId}
      {...(inContent && {
        'data-ad-format': 'fluid',
        'data-ad-layout-key': banner.layoutKey,
      })}
      ref={adRef}
    ></ins>
  )

  if (bottomFixed) {
    return (
      <BottomCon>
        <AdCon vertMargins={1} sideMargins={0.5}>
          {AdElement}
        </AdCon>
      </BottomCon>
    )
  } else {
    return (
      <>
        <AdCon vertMargins={1} sideMargins={0.5}>
          {AdElement}
        </AdCon>
      </>
    )
  }
}
