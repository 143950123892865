import React, { lazy, Suspense } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { ROUTING_URLS } from './Routing/urls'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import DefaultPage from './Pages/DefaultPage/DefaultPage'
import { initFirebase } from './Api/firebase'
import { initializeSentry } from './Config/Sentry/sentry'
import {
  AppContextLayout,
  StatsContextLayout,
  BroadcastViewerContextLayout,
} from './Routing/ContextLayouts'
import { initializeBranch } from './Config/DeepLinks/deeplinks.config'
import { LinkProvider } from './Context/LinkContext'
import { AdProvider } from './Context/AdContext/AdContext'
import { useUserWay } from './Hooks/Scripts/useUserWay'
const StorageProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.StorageProvider }))
)
const Home = lazy(() => import('./Pages/Home'))
const Players = lazy(() => import('./Pages/Players/Players'))
const FullPlayersStatsPage = lazy(
  () => import('./Pages/PlayerStatsTable/FullPlayersStatsPage')
)
const FullTeamsStatsPage = lazy(() => import('./Pages/TeamFullStats/FullTeamStatsPage'))
const Teams = lazy(() => import('./Pages/Teams/Teams'))
const Games = lazy(() => import('./Pages/Games/Games'))
const Game = lazy(() => import('./Pages/SingleGame/SingleGame'))
const Login = lazy(() => import('./Pages/Login'))
const SingleTeam = lazy(() => import('./Pages/Team/SingleTeam'))
const PlayerProfile = lazy(() => import('./Pages/PlayerProfile/PlayerProfile'))
const Fantasy = lazy(() => import('./Pages/Fantasy/Fantasy'))
const AllTimeLeadersPage = lazy(
  () => import('./Pages/AllTimeLeaders/AllTimeLeadersPage')
)
const AdvancedStatsPage = lazy(() => import('./Pages/AdvancedStats/AdvancedStatsPage'))
const BroadcastPlayByPlay = lazy(
  () => import('./Pages/BroadcastPlayByPlay/BroadcastPlayByPlay')
)
const BroadcastGameViewer = lazy(
  () => import('./Pages/BroadcastGameViewer/BroadcastGameViewer')
)

initFirebase()
initializeBranch()
initializeSentry()

export const App: React.FC = () => {
  useUserWay()
  
  return (
    <StorageProvider>
      <HelmetProvider>
        <LinkProvider>
          <AdProvider>
            <Helmet>
              <title>Premier Lacrosse League Stats</title>
              <meta
                name="description"
                content={`Up to date Premier Lacrosse League team, player, and game stats. Stats for the best professional lacrosse players in the world.`}
              />
            </Helmet>
            <Routes>
              <Route element={<AppContextLayout />}>
                <Route path={ROUTING_URLS.LOGIN_URL} element={<Login />} />

                <Route element={<StatsContextLayout />}>
                  <Route
                    path="/"
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <Home />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.PLAYERS_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <Players />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.PLAYER_STATS_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <FullPlayersStatsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.PLAYER_TABLE_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <FullPlayersStatsPage />
                      </Suspense>
                    }
                  />

                  <Route
                    path={ROUTING_URLS.TEAMS_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <Teams />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.TEAM_TABLE_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <FullTeamsStatsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.ALL_TEAM_STATS_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <FullTeamsStatsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.ALL_TEAMS_TABLE_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <FullTeamsStatsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path={ROUTING_URLS.FANTASY_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <Fantasy />
                      </Suspense>
                    }
                  />

                  <Route
                    path={ROUTING_URLS.GAMES_URL}
                    element={
                      <Suspense fallback={<DefaultPage />}>
                        <Games />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path={`${ROUTING_URLS.GAMES_URL}/:year/:id`}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <Game />
                    </Suspense>
                  }
                />

                <Route
                  path={`${ROUTING_URLS.TEAMS_URL}/:teamName`}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <SingleTeam />
                    </Suspense>
                  }
                />

                <Route
                  path={ROUTING_URLS.CAREER_LEADERS_URL}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <AllTimeLeadersPage />
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTING_URLS.ADVANCED_STATS_URL}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <AdvancedStatsPage />
                    </Suspense>
                  }
                />

                <Route
                  path={`${ROUTING_URLS.PLAYERS_URL}/:playerId`}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <PlayerProfile />
                    </Suspense>
                  }
                />
              </Route>

              <Route element={<BroadcastViewerContextLayout />}>
                <Route
                  path={`${ROUTING_URLS.BROADCAST_PLAY_LOGS_URL}/:id`}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <BroadcastPlayByPlay />
                    </Suspense>
                  }
                />
                <Route
                  path={`${ROUTING_URLS.BROADCAST_GAME_VIEW_URL}/:id`}
                  element={
                    <Suspense fallback={<DefaultPage />}>
                      <BroadcastGameViewer />
                    </Suspense>
                  }
                />
              </Route>
            </Routes>
          </AdProvider>
        </LinkProvider>
      </HelmetProvider>
    </StorageProvider>
  )
}
