import { PlayerGameData } from '../players/players.types'
import { API_ENDPOINTS } from '../utils/endpoints'
import { callGraphQL, makeAPIRequest } from '../utils/request'
import {
  eventInfoAndStatsById,
  liveEventInfoAndStats,
  broadcastLiveEventInfoAndStats,
  playLogsByEvent,
  eventInProgressBySlug,
} from './events.queries'
import { EventGQL, EventResponse, EventPlayLogsResponse, PlayLogs } from './events.types'

/**
 * Fetch all event info for specific season
 */
export async function fetchSeasonEvents(
  season: number,
  includeCS?: boolean
): Promise<EventGQL[] | null> {
  try {
    let evsRes = await makeAPIRequest(API_ENDPOINTS.SEASON_EVENTS, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year: season,
        includeCS: includeCS || false,
      },
    })
    if (evsRes?.error) {
      evsRes.error?.message && console.error(evsRes.error?.message)
      return null
    }
    return evsRes?.data
  } catch (error) {
    return null
  }
}

/**
 * Fetch all event info for specific season
 */
export async function fetchSingleEventInfo(slug: string): Promise<EventGQL | null> {
  try {
    let evRes = await makeAPIRequest(`${API_ENDPOINTS.SEASON_EVENTS}/${slug}`, {
      method: 'GET',
      apiVersion: '4'
    })
    if (evRes?.error) {
      evRes.error?.message && console.error(evRes.error?.message)
      return null
    }
    return evRes?.data
  } catch (error) {
    return null
  }
}

/**
 * REST endpoint - Fetch stats for each team
 * @returns Two teams team[0] is away team
 */
export async function fetchEventTeamsStats(slug: string) {
  try {
    const teamsStatsRes = await makeAPIRequest(
      `${API_ENDPOINTS.SEASON_EVENTS}/${slug}/teams/stats`,
      {
        method: 'GET',
        apiVersion: '4',
      }
    )
    if (teamsStatsRes?.error) {
      teamsStatsRes.error?.message && console.error(teamsStatsRes.error?.message)
      return null
    }
    return teamsStatsRes?.data
  } catch (error) {
    return null
  }
}

/**
 * REST endpoint - Fetch stats for each team
 * @returns Two teams team[0] is away team
 */
export async function fetchEventPlayerStats(slug: string): Promise<PlayerGameData[] | null> {
  try {
    const playerStatsRes = await makeAPIRequest(
      `${API_ENDPOINTS.SEASON_EVENTS}/${slug}/players/stats`,
      {
        method: 'GET',
        apiVersion: '4',
      }
    )
    if (playerStatsRes?.error) {
      playerStatsRes.error?.message && console.error(playerStatsRes.error?.message)
      return null
    }
    return playerStatsRes?.data
  } catch (error) {
    return null
  }
}

/**
 * REST endpoint - Fetch play logs for a single game
 */
export async function fetchEventPlayLogs(slug: string): Promise<PlayLogs[]> {
  try {
    const playLogsRes = await makeAPIRequest(
      `${API_ENDPOINTS.SEASON_EVENTS}/${slug}/playlogs`,
      {
        method: 'GET',
        apiVersion: '4',
      }
    )
    if (playLogsRes?.error) {
      playLogsRes.error?.message && console.error(playLogsRes.error?.message)
      return []
    }
    return playLogsRes?.data
  } catch (error) {
    return []
  }
}

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(eventInfoAndStatsById, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for live game
 */
export async function fetchLiveEventByIdGQL(id: string) {
  const ev = await callGraphQL<EventResponse>(liveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventInProgressBySlugGQL(
  slug: string
): Promise<EventResponse | null> {
  try {
    return await callGraphQL<EventResponse>(eventInProgressBySlug, {
      slug: slug,
    })
  } catch (error) {
    return null
  }
}

/**
 * Fetch event info for live game
 */
export async function fetchLiveEventBroadcastViewer(id: string) {
  const ev = await callGraphQL<EventResponse>(broadcastLiveEventInfoAndStats, {
    id: id,
  })
  return ev.event
}

/**
 * Fetch play logs for a single game
 */
export async function fetchPlayLogsByEvent(eventId: string) {
  const ev = await callGraphQL<EventPlayLogsResponse>(playLogsByEvent, {
    eventId,
  })
  return ev.playLogs
}
