import { MasterLinkParams } from '../../Constants'

export const determineMasterParamsToAdd = (
  mParams: MasterLinkParams | null,
  newUser: boolean
) => {
  if (!mParams) return null
  let linkParams: MasterLinkParams = mParams
  if (mParams?.osc) {
    linkParams.osc = mParams.osc
  }
  if (newUser) {
    if (mParams?.pllm_source) {
      linkParams.pllm_source = mParams.pllm_source
    }
    if (mParams?.referrerUserId) {
      linkParams.referrerUserId = mParams.referrerUserId
    }
  }
  return linkParams
}
