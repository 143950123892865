import { styled } from '@mui/system'

export const SlideDownFade = styled('div')(({ theme }) => ({
  width: '100%',
  '@keyframes slideDown': {
    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animation: 'slideDown 0.3s ease-out forwards',
  transformOrigin: 'top',
}))
