import React, { createContext, useState, useEffect, useRef, useMemo } from 'react'
import {
  EventGQL,
  FeedItem,
  fetchEventPlayerStats,
  fetchEventPlayLogs,
  fetchEventTeamsStats,
  fetchHighlightsBySlug,
  fetchSingleEventInfo,
  PlayerGameData,
  PlayLogs,
} from '../../Api'
import { isLiveGame, updateInProgressGame } from '../../Utils'
import { TeamGameStats } from '../../Api/teams/teams.types'

type GameContextType = {
  gameEvent: EventGQL | null
  teamStats: TeamGameStats[]
  players: PlayerGameData[]
  playLogs: PlayLogs[]
  highlights: FeedItem[]
  isEventLoading: boolean
  isLoadingPlayLogs: boolean
  selectedGoal: PlayLogs | null
  promptRefresh: boolean
  winProbabilities: PlayLogs[]
  fetchPlayLogs: (event: EventGQL) => void
  updateSelectedGoal: (playLog: PlayLogs) => void
  fetchGameHighlights: (gameEv: EventGQL) => Promise<(() => void) | undefined>
}

export const GameContext = createContext<GameContextType | null>(null)

export const GameProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [gameEvent, setGameEvent] = useState<EventGQL | null>(null)
  const [teamStats, setTeamStats] = useState<TeamGameStats[]>([])
  const [players, setPlayers] = useState<PlayerGameData[]>([])
  const [playLogs, setPlayLogs] = useState<PlayLogs[]>([])
  const [isEventLoading, setIsEventLoading] = useState<boolean>(true)
  const [highlights, setHighlights] = useState<FeedItem[]>([])
  const [selectedGoal, setSelectedGoal] = useState<PlayLogs | null>(null)
  const [isLoadingPlayLogs, setIsLoadingPlayLogs] = useState<boolean>(false)
  const [promptRefresh, setPromptRefresh] = useState<boolean>(false)
  const [fetchFails, setFetchFails] = useState<number>(0)
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false)
  const gameCheckIntervalRef = useRef<any | null>(null)
  const liveGameIntervalRef = useRef<any | null>(null)

  useEffect(() => {
    let slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    const id = slug.split('?')[0]
    fetchEvent(id)

    return () => {
      if (gameCheckIntervalRef.current !== null) {
        clearInterval(gameCheckIntervalRef.current)
        gameCheckIntervalRef.current = null
        console.log('Cleared game check interval on unmount')
      }
      if (liveGameIntervalRef.current !== null) {
        clearInterval(liveGameIntervalRef.current)
        liveGameIntervalRef.current = null
        console.log('Cleared interval on unmount')
      }
    }
  }, [])

  const winProbabilities = useMemo(() => {
    if (!playLogs || !gameEvent) return []
    const nonNullPlayLogs = playLogs.filter((pl) => pl.awayTeamWinProbability !== null)
    return nonNullPlayLogs
  }, [playLogs])

  const fetchEvent = async (id: string) => {
    try {
      const [singleEvent, teamsStats, plrStats] = await Promise.all([
        fetchSingleEventInfo(id),
        fetchEventTeamsStats(id),
        fetchEventPlayerStats(id),
      ])
      if (!singleEvent) return setIsEventLoading(false)
      setGameEvent(singleEvent)

      if (teamsStats.length > 1) {
        setTeamStats(teamsStats)
      }

      if (plrStats && plrStats.length > 0) {
        setPlayers(plrStats)
      }

      setIsEventLoading(false)
      if (singleEvent.eventStatus > 0) {
        await Promise.all([fetchPlayLogs(singleEvent), fetchGameHighlights(singleEvent)])
      }
      runGameCheckInterval(singleEvent)
      return
    } catch (err) {
      console.error(err)
      setIsEventLoading(false)
      return
    }
  }

  const runGameCheckInterval = (ev: EventGQL) => {
    if (gameCheckIntervalRef.current === null) {
      //Starting interval to go every 3 seconds
      gameCheckIntervalRef.current = setInterval(() => {
        let isLive = isLiveGame(ev)
        if (isLive) {
          startLiveGameInterval(ev)
        }
        checkGameViewerErrors()
      }, 3000)
    } else {
      console.log('Game check interval already running')
    }
  }

  useEffect(() => {
    if (fetchFails >= 5) {
      console.error('Too many fetch failures, prompting refresh...')
      setPromptRefresh(true)
    }
  }, [fetchFails])

  const startLiveGameInterval = async (liveEv: EventGQL) => {
    if (liveGameIntervalRef.current !== null) return

    liveGameIntervalRef.current = setInterval(async () => {
      if (isRequestPending) return

      try {
        setIsRequestPending(true)
        const ev = await updateInProgressGame(liveEv?.slugname)
        setIsRequestPending(false)

        if (!ev) {
          setFetchFails((prev) => prev + 1)
          return
        }

        // Reset fails on successful fetch
        setFetchFails(0)
        setGameEvent(ev)
        await fetchPlayLogs(ev)

        if (!isLiveGame(ev)) {
          if (liveGameIntervalRef.current !== null) {
            clearInterval(liveGameIntervalRef.current)
            liveGameIntervalRef.current = null
          }
        }
      } catch (err) {
        console.error('Error fetching live game data:', err)
        setFetchFails((prev) => prev + 1)
        setIsRequestPending(false)
      }
    }, 20000)
  }

  const fetchPlayLogs = async (event: EventGQL) => {
    if (isLoadingPlayLogs || !event) return
    if (event?.eventStatus === 1 && gameEvent?.id === event.id) {
      await fetchNewPlayLogs(event)
      return
    }

    if (
      playLogs.length > 0 &&
      gameEvent?.eventStatus !== 1 &&
      gameEvent?.id === event.id
    ) {
      setIsLoadingPlayLogs(false)
      return Promise.resolve(playLogs)
    } else {
      //Need to get play logs from DB
      await fetchNewPlayLogs(event)
      return
    }
  }

  const fetchNewPlayLogs = async (event: EventGQL) => {
    try {
      setIsLoadingPlayLogs(true)
      const logs = await fetchEventPlayLogs(event.slugname)
      if (!logs || logs.length === 0) return []
      const filtered = logs.filter(
        (l) =>
          !l.markerId.includes('-G') &&
          l.eventType !== 'periodend' &&
          l.eventType !== 'start' &&
          l.eventType !== 'periodstart'
      )
      setPlayLogs(filtered)
      setIsLoadingPlayLogs(false)
      return
    } catch (err) {
      setIsLoadingPlayLogs(false)
      console.log(err)
      return
    }
  }

  const fetchGameHighlights = async (gameEv: EventGQL) => {
    const highlights = await fetchHighlightsBySlug(gameEv.slugname)
    setHighlights(highlights)

    if (gameEv?.eventStatus === 1) {
      const interval = setInterval(() => {
        //Starting interval to go every 60 seconds
        fetchHighlightsBySlug(gameEv.slugname).then((vids) => {
          if (vids && vids.length > 0)
            vids.length > highlights.length && setHighlights(vids)

          if (!gameEv) {
            clearInterval(interval)
            return
          }
          if (gameEv?.eventStatus >= 2) {
            clearInterval(interval)
            return
          }
          if (gameEv?.eventStatus === 0) {
            clearInterval(interval)
            return
          }
        })
      }, 60000)
      return () => clearInterval(interval)
    }
    return
  }

  const updateSelectedGoal = (playLog: PlayLogs) => {
    setSelectedGoal(playLog)
  }
  const checkGameViewerErrors = () => {
    if (fetchFails >= 5) {
      console.error('Too many fetch failures, resetting page...')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  return (
    <GameContext.Provider
      value={{
        gameEvent,
        teamStats,
        players,
        playLogs,
        highlights,
        isEventLoading,
        isLoadingPlayLogs,
        selectedGoal,
        promptRefresh,
        winProbabilities,
        fetchPlayLogs,
        updateSelectedGoal,
        fetchGameHighlights,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}
