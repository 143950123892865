export const BRAZE_EVENTS = {
    log_in: 'logged_in',
    log_out: 'logged_out',
    on_site_check_in: 'on_site_check_in',
    page_view: 'page_view_stats_', //Adding stats to differentiate between web main & stats
    // Anonymous user events
    anonymous_session_start: 'anonymous_session_start',
    anonymous_to_identified: 'anonymous_to_identified',
}

//Analytics dash: https://www.notion.so/premierlacrosseleague/Analytics-Tags-Database-ca224c3268ec4c7c914c408b3e68b396?pvs=4
export const ANALYTICS_TAGS = {
    // Login //
    login_click: 'login_click',
    phone_login_click: 'phone_login_click',
    email_login_click: 'email_login_click',
    google_login_click: 'google_login_click',
    login_cancelled: 'login_cancelled',
  
    // User //
    fav_team_click: 'fav_team_click',
  
    // SEASON //
    season_selection_change: 'season_selection_change',
    week_selection_change: 'week_selection_change',
  
    // VIDEO //
    video_start: 'video_start',
    video_watched: 'video_watched',

    // GENERAL //
    watch_click: 'watch_click', // clicking watch button on game tickers

    // SINGLE GAME //
    game_player_stats_tab_click: 'game_player_stats_tab_click',
    game_flow_goal_click: 'game_flow_goal_click',
    play_by_play_type_select: 'play_by_play_type_select',
    boxscore_highlight_click: 'boxscore_highlight_click',
  
    //OTHER //
    app_callout_click: 'app_callout_click',
  }