import React, { createContext, useContext, useEffect, useState } from 'react'
import { ADSENSE_CLIENT_ID } from '../../Config/Ads/ads.config'

interface AdContextProps {
  scriptLoaded: boolean
  canShowAds: boolean
  hasError: boolean
  errorMessage: string | undefined
}

const AdContext = createContext<AdContextProps | null>(null)
export const useAdContext = () => useContext(AdContext)!

export const AdProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [canShowAds, setCanShowAds] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const ADS_SCRIPT_SRC = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${ADSENSE_CLIENT_ID}`

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.adsbygoogle = window.adsbygoogle || []
    const existingScript = document.querySelector(`script[src="${ADS_SCRIPT_SRC}"]`)
    if (existingScript) {
      setScriptLoaded(true)
      setCanShowAds(true)
      return
    }
    const script = document.createElement('script')
    script.src = ADS_SCRIPT_SRC
    script.async = true
    script.crossOrigin = 'anonymous'
    script.onload = () => {
      setScriptLoaded(true)
      setCanShowAds(true)
      setHasError(false)
      setErrorMessage(undefined)
    }
    script.onerror = (error) => {
      setHasError(true)
      setErrorMessage(`Failed to load AdSense script: ${error}`)
      setCanShowAds(false)
    }
    document.head.appendChild(script)

    return () => {
      // Only remove if it's the script we added
      const scriptToRemove = document.querySelector(`script[src="${ADS_SCRIPT_SRC}"]`)
      if (scriptToRemove && !existingScript) {
        document.head.removeChild(scriptToRemove)
      }
    }
  }, [])

  return (
    <AdContext.Provider
      value={{ scriptLoaded, canShowAds, hasError, errorMessage }}
    >
      {children}
    </AdContext.Provider>
  )
}