export const initializeBranch = async () => {
  if (!process.env.REACT_APP_BRANCH_KEY) return false
  if(window?.branch){
    console.log('Branch already initialized')
     return true 
    }
  try {
    const branch = await import('branch-sdk')
    if (!branch) return false
    branch.init(process.env.REACT_APP_BRANCH_KEY, {}, (err, data) => {
      if (err) {
        console.error('Error initializing Branch', err)
        console.error(err)
        return false
      }
      console.log('Branch initialized')
      window.isBranchInitialized = true
      return true
    })
    return
  } catch (err) {
    console.error('Issue init Branch', err)
    return false
  }
}
