import { logEvent } from '../../Utils'

// Cache for the Braze SDK
let brazeSDKCache: any = null

/**
 * Gets the Braze SDK, caching it after the first import
 * @returns The Braze SDK or null if import fails
 */
export const getBrazeSDK = async () => {
  if (brazeSDKCache) {
    return brazeSDKCache
  }

  try {
    brazeSDKCache = await import('@braze/web-sdk')
    return brazeSDKCache
  } catch (error) {
    console.error('Error importing Braze SDK:', error)
    logEvent('brazeError_importSDK_stats', {
      provider: 'braze',
      error,
      product: 'stats',
    })
    return null
  }
}

/**
 * Core function to initialize Braze with common configuration
 * @param userId Optional user ID for identified users
 * @returns Whether initialization was successful
 */
export const initBrazeCore = async (userId?: string): Promise<boolean> => {
  if (!process.env.REACT_APP_BRAZE_API_KEY) {
    console.log('Braze not initialized - missing API key')
    return false
  }

  try {
    const braze = await getBrazeSDK()
    if (!braze) return false

    const { initialize, changeUser, openSession } = braze
    const success = initialize(process.env.REACT_APP_BRAZE_API_KEY, {
      baseUrl: process.env.REACT_APP_BRAZE_API_URL || 'sdk.iad-05.braze.com',
      enableLogging: process.env.NODE_ENV === 'development',
      minimumIntervalBetweenTriggerActionsInSeconds: 10,
    })

    if (success) {
      if (userId) {
        changeUser(userId)
      }
      openSession()
      return true
    }
    return false
  } catch (error) {
    const context = userId ? 'initBraze' : 'initBrazeAnonymous'
    console.error(`Error initializing Braze in ${context}:`, error)
    logEvent(`brazeError_${context}_stats`, {
      provider: 'braze',
      error,
      product: 'stats',
    })
    return false
  }
}

/**
 * Signs out the current user from Braze
 * @returns Whether the sign out was successful
 */
export const signOutBraze = async (): Promise<boolean> => {

  try {
    const braze = await getBrazeSDK()
    if (!braze) return false

    const { wipeData } = braze
    wipeData()

    return true
  } catch (error) {
    logEvent('brazeError_signOut_stats', {
      provider: 'braze',
      error,
      product: 'stats',
    })
    return false
  }
}