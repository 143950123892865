import { NO_USER } from '../firebase/AuthData'
import { FavType } from '../firebase/AuthData'
import { makeAPIRequest, QueryParams } from '../utils/request'
import { getFirAuth } from '../firebase/firebaseConnect'
import { USER_ENDPOINTS } from '../utils/endpoints'
import { getFirUserAndStatus } from '../../Utils'
import type { UserUpdateData, User } from './types'
import type { APIRequestParams } from '../utils/request'
import type { MasterLinkParams } from '../../Constants'

export type FavoritesProps = {
  uid: string | null
  email: string | null
  item: string
  favType: FavType
  favorites: string[] | []
  team?: string
}

export type TeamFavoriteProps = {
  uid: string | null
  email: string | null
  team: string
}

export const getUser = async (linkParams: MasterLinkParams | null) => {
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (user) {
    const token = await user.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let requestObj: APIRequestParams = {
    method: 'GET',
    apiVersion: '4',
    headerParams: headerParams,
  }
  if (linkParams) {
    requestObj.queryParams = {
      ...linkParams,
    }
  }
  const response = await makeAPIRequest(USER_ENDPOINTS.GET_OR_CREATE_USER, requestObj)
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return null
  }
  return response?.data
}

export const createUser = async (data: User, linkParams: MasterLinkParams | null) => {
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (!user) {
    return Promise.resolve(NO_USER)
  }
  const token = await user.getIdToken()
  headerParams.Authorization = `Bearer ${token}`
  if (linkParams) {
    data = { ...data, ...linkParams }
  }
  let body = JSON.stringify(data)
  const response = await makeAPIRequest(USER_ENDPOINTS.GET_OR_CREATE_USER, {
    method: 'POST',
    apiVersion: '4',
    headerParams: headerParams,
    body: body,
  })
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return null
  }
  return response?.data
}

export const updateUser = async (data: UserUpdateData) => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return false
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(USER_ENDPOINTS.UPDATE_USER, {
      method: 'PUT',
      apiVersion: '4',
      headerParams: headerParams,
      body: body,
    })
    if (response?.error) {
      console.error(response.error?.message || 'Unknown error')
      return false
    }
    return true
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}
